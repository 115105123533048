<template>
    <div class="animated fadeIn">
        <wit-tabbed-form
            :formId="formId"
            :steps="steps"
            :tab-index="tabIndex"
            headerText="Setup Your Project"
            @continue="continueForm"
            @reset="resetForm"
            ref="tabbedForm"
        >
            <template v-slot:step-1-form="{step}">
                <b-form-group label="Project name" :label-cols="2" :horizontal="true">
                    <b-row>
                        <b-col md="8">
                            <b-form-input
                                v-model="$v.createForm.name.$model"
                                type="text"
                                name="Project name"
                                placeholder="Enter your project name"
                                :id="`${formId}-name`"
                            ></b-form-input>
                            <Feedback
                                :state="validateRef('createForm.name', step)"
                                invalid="This field is required and needs to be 25 characters at most"
                                valid="Project name is valid"
                            ></Feedback>
                        </b-col>
                    </b-row>
                </b-form-group>

                <b-form-group label="Data Location" :horizontal="true" :label-cols="2">
                    <b-row>
                        <b-col md="8">
                            <wit-select
                                :options="locations"
                                v-model="$v.createForm.location.$model"
                                placeholder="Select location"
                                :custom-label="prettyLocation"
                                :id="`${formId}-location`"
                            ></wit-select>
                            <Feedback
                                :state="validateRef('createForm.location', step)"
                                invalid="This field is required"
                                valid="Location is valid"
                            ></Feedback>
                        </b-col>
                    </b-row>
                </b-form-group>
                <b-row>
                    <b-col>
                        <Feedback :state="projectError.state" :invalid="projectError.message"></Feedback>
                    </b-col>
                </b-row>
            </template>
            <template v-slot:step-1-footer="{step}">
                <b-row>
                    <b-col>
                        <Feedback
                            v-if="step.checked"
                            :state="validateRef('createForm', step)"
                            invalid="You need to finish this step before accessing the next one"
                            valid="This step is complete"
                        ></Feedback>
                        <b-button
                            @click="() => createProject(step)"
                            :disabled="(step.checked && $v.createForm.$invalid) || $store.state.loading.processing"
                            variant="primary"
                            :id="`${formId}-create`"
                        >
                            <i class="icon-check"></i> Next
                        </b-button>
                    </b-col>
                </b-row>
            </template>
            <template v-slot:step-2-form="{step}">
                <b-row>
                    <b-col md="2" />
                    <b-col>
                        <b-row>
                            <b-col md="8">
                                <span class="small-info-text">
                                    Here is dedicated service account for your WitCloud project. The last thing to do is
                                    to add the appropriate access to this service account. We've created
                                    <a :href="projectCodelabUrl" target="_blank">special instructions</a>
                                    on how to do this. After you give necessary access click Refresh and choose a proper
                                    GCP project. Then finish by clicking the Submit button below.
                                </span>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>

                <b-form-group label="Dedicated Service Account" :label-cols="2" :horizontal="true">
                    <b-row>
                        <b-col md="8">
                            <copy-text :id="`${formId}-service-account`" v-model="serviceAccount" />
                        </b-col>
                    </b-row>
                </b-form-group>

                <b-form-group label="Google Cloud Project" :horizontal="true" :label-cols="2">
                    <b-row class="refresh-projects-row">
                        <b-col md="8">
                            <wit-select
                                :options="gcpProjects"
                                v-model="$v.configureForm.project.$model"
                                :preselectFirst="true"
                                placeholder="Select a GCP Project"
                                :id="`${formId}-project`"
                            ></wit-select>
                            <Feedback
                                :state="validateRef('configureForm.project', step)"
                                invalid="This field is required"
                                valid="Project is valid"
                            ></Feedback>
                        </b-col>
                        <b-col class="refresh-projects-col">
                            <b-button
                                @click="fetchGcpProjects"
                                variant="warning"
                                v-b-tooltip.hover
                                title="If a project does not show up even though you have granted access, 
                                                    please wait a few seconds and refresh again. It takes a moment for the data to synchronize fully."
                                :id="`${formId}-refresh`"
                            >
                                <i class="icon-refresh"></i> Refresh
                            </b-button>
                        </b-col>
                    </b-row>
                </b-form-group>
                <b-row>
                    <b-col>
                        <Feedback :state="projectError.state" :invalid="projectError.message"></Feedback>
                    </b-col>
                </b-row>
            </template>
            <template v-slot:step-2-footer="{step}">
                <b-row>
                    <b-col>
                        <b-button
                            :disabled="step.checked && $v.configureForm.$invalid"
                            @click="() => configureProject(step)"
                            type="button"
                            variant="primary"
                            :id="`${formId}-configure`"
                        >
                            <i class="icon-check"></i> Submit
                        </b-button>
                    </b-col>
                </b-row>
            </template>
        </wit-tabbed-form>
        <Loading :loading="$store.state.loading.processing" :text="$store.state.loading.text"></Loading>
    </div>
</template>

<script>
import {validationMixin} from 'vuelidate'
import {required, maxLength} from 'vuelidate/lib/validators'
import {mapGetters} from 'vuex'

import CopyText from '@/components/CopyText.vue'
import Loading from '@/components/loading.vue'
import Feedback from '@/components/Feedback'
import WitTabbedForm from '@/components/WitTabbedForm.vue'

import {formMixin} from '@/mixins/formMixin'

export default {
    components: {
        CopyText,
        Loading,
        Feedback,
        WitTabbedForm,
    },
    mixins: [formMixin, validationMixin],
    data() {
        return {
            formId: 'project-create-form',
            cannotContinue: true,
            createForm: {
                name: null,
                location: null,
            },
            configureForm: {
                project: null,
            },
            projectError: {
                state: null,
                message: '',
            },
            showHelp: false,
            gcpProjects: [],
            witcloudProject: null,
            serviceAccount: null,
            projectConfigured: false,
            projectCodelabUrl:
                'https://witbee.com/docs/start/how-to-start/#step-2-creating-an-account-and-creating-a-project-in-the-witcloud-platform',
            tabIndex: 0,
            billingAccount: null,
        }
    },
    computed: {
        ...mapGetters({
            activeProject: 'project/active',
            billingAccounts: 'billing/accounts',
            activeBillingAccount: 'billing/active',
            locations: 'bigquery/locations',
        }),
        steps() {
            return [
                {
                    name: 'Project Settings',
                    invalid: this.$v.createForm.$invalid,
                    customToggleInvalid: Boolean(this.witcloudProject),
                },
                {
                    name: 'Configure Access',
                    invalid: this.$v.configureForm.$invalid,
                    customToggleInvalid: !this.witcloudProject || Boolean(this.projectConfigured),
                },
            ]
        },
    },
    validations: {
        createForm: {
            name: {
                required,
                maxLength: maxLength(25),
            },
            location: {required},
        },
        configureForm: {
            project: {required},
        },
    },
    async created() {
        this.$store.commit('loading/PROCESSING', `Preparing the form...`)

        await Promise.all([this.$store.dispatch('bigquery/getLocations'), this.$store.dispatch('billing/getAccounts')])

        const {id} = this.$route.query
        if (id) {
            try {
                await this.fetchProject(id)
                await this.prepareConfigureForm()
                await this.$store.dispatch('project/getProjects')
                this.tabIndex = 1
            } catch (exception) {
                this.$store.commit('loading/PROCESSED')
                this.$errorHandler.report(exception, 'Could not prepare configure project form')
                this.$projectRouter.push('/iam/projects/create')
            }
        }

        const formSessionMap = JSON.parse(localStorage.getItem('formSessionIdMap'))
        const formSessionId = formSessionMap[this.$route.path].id

        this.$gtm.trackEvent({
            event: 'create_resource_start',
            action: 'create',
            value: formSessionId,
        })

        this.$store.commit('loading/PROCESSED')
    },
    methods: {
        closeHelp() {
            this.showHelp = false
            this.authNotClicked = true
        },

        async createProject(step) {
            step.check()
            this.$forceUpdate()
            if (!this.$v.createForm.$invalid) {
                this.projectError = {
                    state: true,
                    message: '',
                }

                const billingAccount = this.activeBillingAccount || this.billingAccounts[0]

                const data = {
                    name: this.createForm.name,
                    location: this.createForm.location.name,
                    billingAccountId: billingAccount.id,
                }

                this.$store.commit('loading/PROCESSING', `Creating the Project...`)
                try {
                    this.loadingInterval = setInterval(
                        () => this.$store.commit('loading/PROCESSING', `Creating the Project...`),
                        10000
                    )
                    const response = await this.axios.post(`${process.env.VUE_APP_NODE_API_HOST}/projects`, data)
                    this.witcloudProject = response.data.data
                    await this.$store.dispatch('project/setProject', this.witcloudProject)

                    await this.prepareConfigureForm()

                    step.nextPage()
                    clearInterval(this.loadingInterval)
                    this.loadingInterval = null

                    this.$store.commit('loading/PROCESSED')
                } catch (e) {
                    clearInterval(this.loadingInterval)
                    this.loadingInterval = null

                    this.projectError.state = false
                    this.projectError.message = e.response.data.data
                    this.$store.commit('loading/PROCESSED')
                    this.$errorHandler.report(e, 'Could not create project')
                }
            }
        },

        async fetchProject(id) {
            const projects = await this.$store.dispatch('project/getProjects')
            this.witcloudProject = projects.find(el => el.id === id)
            this.createForm = {
                name: this.witcloudProject.name,
                location: this.witcloudProject.location,
            }
        },

        async prepareConfigureForm() {
            const serviceAccountResponse = await this.axios.get(
                `${process.env.VUE_APP_NODE_API_HOST}/serviceAccounts/main?projectId=${this.witcloudProject.id}`
            )
            this.serviceAccount = serviceAccountResponse.data.data.serviceAccountData.email

            const projectsResponse = await this.axios.get(
                `${process.env.VUE_APP_NODE_API_HOST}/google/projects?witcloudProjectId=${this.witcloudProject.id}`
            )
            this.gcpProjects = projectsResponse.data.data.filter(el => el !== process.env.VUE_APP_CLOUD_PROJECT)

            if (this.gcpProjects.length > 0) {
                this.configureForm.project = this.gcpProjects[0]
            }
        },

        async configureProject(step) {
            step.check()
            this.$forceUpdate()
            if (!this.$v.configureForm.$invalid) {
                this.projectError = {
                    state: true,
                    message: '',
                }

                const data = {
                    witcloudProjectId: this.witcloudProject.id,
                    googleCloudProjectId: this.configureForm.project,
                }

                this.$store.commit('loading/PROCESSING', `Configuring the Project...`)
                this.loadingInterval = setInterval(
                    () => this.$store.commit('loading/PROCESSING', `Configuring the Project...`),
                    10000
                )

                try {
                    await this.axios.post(`${process.env.VUE_APP_NODE_API_HOST}/projects/configure`, data)

                    this.projectConfigured = true
                    this.resetForm()

                    const projects = await this.$store.dispatch('project/getProjects')
                    this.witcloudProject = projects.find(el => el.id === this.witcloudProject.id)
                    await this.$store.dispatch('project/setProject', this.witcloudProject)

                    clearInterval(this.loadingInterval)
                    this.loadingInterval = null

                    const formSessionMap = JSON.parse(localStorage.getItem('formSessionIdMap'))
                    const formSessionId = formSessionMap[this.$route.path].id

                    this.$gtm.trackEvent({
                        event: 'create_resource_finish',
                        action: 'finish',
                        value: formSessionId,
                    })

                    formSessionMap[this.$route.path] = null
                    localStorage.setItem('formSessionIdMap', JSON.stringify(formSessionMap))

                    this.$projectRouter.push('/reports/dataproviders/source')

                    this.$store.commit('loading/PROCESSED')
                } catch (exception) {
                    clearInterval(this.loadingInterval)
                    this.loadingInterval = null

                    this.projectError.state = false
                    this.projectError.message = exception.response.data.data
                    this.$errorHandler.report(exception, 'Could not configure project')
                    this.$store.commit('loading/PROCESSED')
                }
            }
        },

        async fetchGcpProjects() {
            this.$store.commit('loading/PROCESSING', `Refreshing the projects...`)
            const projectsResponse = await this.axios.get(
                `${process.env.VUE_APP_NODE_API_HOST}/google/projects?witcloudProjectId=${this.witcloudProject.id}`
            )
            this.gcpProjects = projectsResponse.data.data.filter(el => el !== process.env.VUE_APP_CLOUD_PROJECT)
            if (this.gcpProjects.length > 0) {
                this.configureForm.project = this.gcpProjects[0]
            }
            this.$store.commit('loading/PROCESSED')
        },

        prettyLocation(option) {
            return `${option.name} (${option.description})`
        },
    },
}
</script>

<style lang="scss">
.refresh-projects-row {
    .refresh-projects-col {
        .btn {
            margin-top: 4px;
        }
    }
}

.small-info-text {
    padding-bottom: 10px;
}

#project-create-form-billing-account-create {
    i {
        font-size: 1rem;
        padding-top: 10px;
    }
}
</style>

<style scoped>
.close-button {
    font-size: 1.5rem;
}
</style>
